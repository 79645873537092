export const theme = {
    dark: false,
    colors: {
        primary: '#5D814C',
        secondary: '#273635',
        background: '#F2F2F2',
        card: '#FFFFFF',
        text: '#273635',
        border: '#F2F2F2',
        notification: '',
        opaque: '#4E5650',
        white: '#FFFFFF',
    },
};
