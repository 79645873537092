import { SafeAreaView } from 'react-native-safe-area-context';
import { Text } from 'react-native';
import { StyleSheet } from 'react-native';
import Ionicons from '@expo/vector-icons/Ionicons';
import { theme } from '../themes/default.theme';

export default function CourtsPage() {
    return (
        <SafeAreaView style={styles.container}>
            <Ionicons name="golf-sharp" color={theme.colors.opaque} size={64} />
            <Text>Find Nearby Courts</Text>
            <Text>Coming Soon</Text>
        </SafeAreaView>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        gap: 10,
    },
});
