import { NavigationContainer } from '@react-navigation/native';
import HomePage from './pages/home.page';
import CourtsPage from './pages/courts.page';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import TourneysPage from './pages/tourneys.page';
import SettingsPage from './pages/settings.page';
import RivalsPage from './pages/rivals.page';
import Ionicons from '@expo/vector-icons/Ionicons';
import { useIsFocused } from '@react-navigation/native';
import { theme } from './themes/default.theme';
import { loadAsync } from 'expo-font';
import { useEffect } from 'react';
import { StatusBar } from 'expo-status-bar';

const Tab = createBottomTabNavigator();

export default function App() {
    const loadFonts = async () =>
        await loadAsync('NicoMoji', './assets/fonts/NicoMoji-Regular.ttf');

    useEffect(() => {
        loadFonts();
    }, []);

    return (
        <NavigationContainer theme={theme}>
            <StatusBar style={theme.dark ? 'light' : 'dark'} />
            <Tab.Navigator screenOptions={() => TabStyles}>
                <Tab.Screen
                    name="Home"
                    component={HomePage}
                    options={HomeOptions}
                />
                <Tab.Screen
                    name="Rivals"
                    component={RivalsPage}
                    options={RivalsOptions}
                />
                <Tab.Screen
                    name="Tourneys"
                    component={TourneysPage}
                    options={TourneysOptions}
                />
                <Tab.Screen
                    name="Courts"
                    component={CourtsPage}
                    options={CourtsOptions}
                />
                <Tab.Screen
                    name="Settings"
                    component={SettingsPage}
                    options={SettingsOptions}
                />
            </Tab.Navigator>
        </NavigationContainer>
    );
}

const TabStyles = {
    tabBarActiveTintColor: theme.colors.primary,
    tabBarInactiveTintColor: theme.colors.opaque,
    headerTintColor: theme.colors.text,
    headerStyle: {
        backgroundColor: theme.colors.background,
    },
    headerTitleStyle: {
        fontFamily: 'NicoMoji',
        fontSize: 32,
    },
};

const HomeOptions = {
    headerTitle: 'PicklePal',
    tabBarIcon: () => {
        const isFocused = useIsFocused();
        return isFocused ? (
            <Ionicons name="home" color={theme.colors.primary} size={24} />
        ) : (
            <Ionicons
                name="home-outline"
                color={theme.colors.opaque}
                size={24}
            />
        );
    },
};

const RivalsOptions = {
    tabBarIcon: () => {
        const isFocused = useIsFocused();
        return isFocused ? (
            <Ionicons name="people" color={theme.colors.primary} size={24} />
        ) : (
            <Ionicons
                name="people-outline"
                color={theme.colors.opaque}
                size={24}
            />
        );
    },
};

const TourneysOptions = {
    tabBarIcon: () => {
        const isFocused = useIsFocused();
        return isFocused ? (
            <Ionicons name="trophy" color={theme.colors.primary} size={24} />
        ) : (
            <Ionicons
                name="trophy-outline"
                color={theme.colors.opaque}
                size={24}
            />
        );
    },
};

const CourtsOptions = {
    tabBarIcon: () => {
        const isFocused = useIsFocused();
        return isFocused ? (
            <Ionicons name="golf" color={theme.colors.primary} size={24} />
        ) : (
            <Ionicons
                name="golf-outline"
                color={theme.colors.opaque}
                size={24}
            />
        );
    },
};

const SettingsOptions = {
    tabBarIcon: () => {
        const isFocused = useIsFocused();
        return isFocused ? (
            <Ionicons name="settings" color={theme.colors.primary} size={24} />
        ) : (
            <Ionicons
                name="settings-outline"
                color={theme.colors.opaque}
                size={24}
            />
        );
    },
};
